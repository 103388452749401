const data = {
  works: [
    {
      year: '2020 - 2022',
      name: 'Perpetual Protocol',
      position: 'Sr.Developer',
      link: 'https://perp.com/',
      highlights: [
        'Welcome to WEB3!!!',
        'Welcome Decentralized & Open Source!!!',
        'Tokenomic & Public Goods.',
        'Doc Doc Doc, Read Read Read.',
        'Leverage, Financial, LUNA, FTX...',
      ],
      tags: [
        'Typescript',
        'React',
        'Git',
        'NodeJS',
        'EtherJS',
        'Web3JS',
        'Merkle Tree',
        'CI/CD',
        'AWS',
        'Bugsnag',
        'Segment',
        'Pair Programming',
        'Discord',
        'Gather',
      ],
      desc: 'Codegiant is a git resources platform.',
    },
    {
      year: '2019 - 2020',
      name: 'Codegiant',
      position: 'Consultant & Sr.Developer',
      link: 'https://codegiant.io/home',
      highlights: [
        'Say goodbye to git flow and embrace the trunk based development.',
        'Hmm, git protocol, handshake, hook and file pack, yummy.',
        'Had a memorable time to play with Ruby on Rails.',
        'Integrated DB from MongoDB & SQLite to PostgreSQL.',
        'Hiring people is not an easy job.',
      ],
      tags: [
        'Ruby on Rails',
        'GraphQL',
        'PostgreSQL',
        'React',
        'Git',
        'Python',
        'Jupyter',
        'Linux',
        'Docker',
        'MongoDB',
        'Styled Components',
        'Scrum',
        'Webpack',
        'Slack',
      ],
      desc: 'Codegiant is a git resources platform.',
    },
    {
      name: 'Catchplay',
      year: '2018 - 2019',
      position: 'Front-end Engineer',
      link: 'https://www.catchplay.com/',
      highlights: [
        'Payment system, you had to keep in acumen for everything.',
        'GTM is my friend with our analysts.',
        '"TV Series" is a big feature!',
        'The developer war room is astonishing. It will be closed when we win the game.',
        'Hooray! A magic campaign website controlled by CMS.',
      ],
      tags: ['React', 'Redux', 'GTM', 'Git', 'Webpack', 'Slack', 'NodeJs'],
      desc: 'Catchplay is an OTT company.',
    },
    {
      name: 'Fun.Crea.Free',
      year: '2016 - 2018',
      position: 'Co-Founder',
      link: '',
      highlights: [
        'Standardized the workflow SOP for the team.',
        'Demand interview is an interesting process.',
        'Team mates are important!',
        'You have almost to do everything as a Co-Founder.',
        "Let's try again.",
      ],
      tags: [
        'Laravel',
        'AWS EC2',
        'Sketch',
        'Git',
        'SCSS',
        'NodeJS',
        'Management',
      ],
      desc: 'Fun.Crea.Free is an outsourcing company.',
    },
    {
      name: 'Tiger Party',
      year: '2013 - 2014',
      position: 'Engineer',
      link: 'https://thetigerparty.com/en',
      highlights: [
        'Warning! Handle the billboard system which is running alive.',
        'Be an app developer needs more patient',
        'Providing technical support in English is excited.',
        'More critical, more risks, more exciting.',
        'Trust is important.',
      ],
      tags: [
        'Android',
        'XCode',
        'PHP',
        'Cordova',
        'Git',
        'CI/CD',
        'Router Machine',
        'NYC',
      ],
      desc: 'Tiger Party focus on digital billboard system.',
    },
  ],
};

export default data;
